import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Determines if the current device is a mobile device.
 *
 * @param {Navigator} [_navigator=navigator] The `Navigator` object, defaults to the global navigator object.
 * @returns {boolean} Returns `true` if the device is identified as a mobile device, `false` otherwise.
 */
export const isMobile = (_navigator: Navigator = navigator): boolean => {
  const userAgent = _navigator.userAgent || _navigator.vendor;

  const isAndroidDevice = /android/i.test(userAgent);
  const isAppleDevice = /iPhone|iPod|iPad/.test(userAgent);

  const _isIpad = Boolean(
    /Macintosh/i.test(_navigator.userAgent) && _navigator.maxTouchPoints && _navigator.maxTouchPoints > 1
  );

  return Boolean(isAndroidDevice || isAppleDevice || _isIpad);
};

/**
 * Creates an observable that emits an event when the document becomes visible.
 *
 * @param {Document} [_document=document] The `Document` object, defaults to the global document object.
 * @returns {Observable<unknown>} An observable that emits an event when the document's visibility changes to visible.
 */
export const onResume = (_document: Document = document): Observable<unknown> =>
  fromEvent(_document, 'visibilitychange').pipe(filter(() => !_document.hidden));
