<h1 *ngIf="showHeader" class="header">
  <p class="header-latest">{{ 'FINDER.LATEST_SEARCHES' | translate }}</p>
  <a class="header-see-all" (click)="removeHistory()">{{ 'FINDER.DELETE' | translate }}</a>
</h1>

<h1 *ngIf="showPredictive" class="header">
  <p class="header-predictive">{{ 'FINDER.' + activeSection | uppercase | translate }}</p>
  <a class="header-see-all" (click)="searchByTerm(searchTerm)">{{ 'FINDER.SEE_ALL' | translate }}</a>
</h1>
<hr *ngIf="showPredictive" />

<section *ngIf="existRecords(); else noResults">
  <ng-container *ngFor="let record of records; trackBy: trackByItems; let i = index">
    <p class="results" (click)="searchByHistory(record)" [innerHTML]="record"></p>
  </ng-container>
</section>

<ng-template #noResults>
  <p *ngIf="!needMoreChars" color="header-text">{{ 'FINDER.NO_SEARCHES' | translate }}</p>
</ng-template>

<section *ngIf="needMoreChars">
  <p color="header-text">{{ 'FINDER.LESS_THAN_3_CHARS' | translate }}</p>
</section>
