import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import { ExampleRequestStatusEnum } from '@app/shared/interfaces/multimedia/example.request.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ItemCardType } from '@shared/enums/cards/item-card-type.enum';
import { MultimediaDetailRenderMode } from '@shared/enums/multimedia/multimedia-detail.enum';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';
import { SwiperOptions } from 'swiper';
import { SwiperDirective } from '@app/shared/directives/swiper.directive';

@Component({
  selector: 'app-multimedia-list',
  templateUrl: './multimedia-list.component.html',
  styleUrls: ['./multimedia-list.component.scss']
})
export class MultimediaListComponent implements AfterViewInit {
  private static readonly slidesSizesDefault = {
    VIEWS_MOBILE: 1,
    VIEWS_TABLET_PORTRAIT: 1.5,
    VIEWS_TABLET_LANDSCAPE: 2,
    VIEWS_DESKTOP: 3,
    SPACE_MOBILE: 10,
    SPACE_TABLET_PORTRAIT: 15,
    SPACE_TABLET_LANDSCAPE: 20,
    SPACE_DESKTOP: 30
  };

  @ViewChild(SwiperDirective) swiperDirective!: SwiperDirective;

  @Input() multimediaItemList!: AbstractMultimediaItem[];
  @Input() max!: number;
  @Input() cardType!: ItemCardType;
  @Input() exampleStatus!: ExampleRequestStatusEnum;
  @Input() slideMode = false;
  @Input() lastViewed!: number;
  @Input() pager = true;
  @Input() slidesSizes: MSafeAny = {};
  @Input() detailRenderMode!: MultimediaDetailRenderMode;
  @Input() pageAnalytics!: string;
  @Input() isWebView!: boolean;

  @Output() slideChange = new EventEmitter();

  viewSlidesOptions: SwiperOptions = {};
  exampleOptions = [];

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.updateSlides(window.innerWidth);
  }

  ngAfterViewInit() {
    if (!this.slideMode) {
      return;
    }

    this.updateSlides(window.innerWidth);
  }

  updateSlides(screenWidth: number) {
    const sizes = { ...MultimediaListComponent.slidesSizesDefault, ...this.slidesSizes };
    let slidesPerView = sizes.VIEWS_DESKTOP;
    let spaceBetween = sizes.SPACE_DESKTOP;

    if (screenWidth < 576) {
      slidesPerView = sizes.VIEWS_MOBILE;
      spaceBetween = sizes.SPACE_MOBILE;
    } else if (screenWidth < 768) {
      slidesPerView = sizes.VIEWS_TABLET_PORTRAIT;
      spaceBetween = sizes.SPACE_TABLET_PORTRAIT;
    } else if (screenWidth < 992) {
      slidesPerView = sizes.VIEWS_TABLET_LANDSCAPE;
      spaceBetween = sizes.SPACE_TABLET_LANDSCAPE;
    }

    this.viewSlidesOptions = {
      pagination: { el: '.swiper-pagination', clickable: true },
      slidesPerView,
      spaceBetween
    };

    this.swiperDirective.initializeSwiper();
  }

  onActiveSlideChange() {
    this.slideChange.emit();
  }

  trackById(_index: MSafeAny, value: AbstractMultimediaItem) {
    return value && value.id;
  }
}
