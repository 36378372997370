import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-scrollable-x',
  templateUrl: './scrollable-x.component.html',
  styleUrls: ['./scrollable-x.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableXComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('container') container!: ElementRef<HTMLDivElement>;

  @Input() scrollToElementID!: string;

  @Output() arrowClick = new EventEmitter<void>();

  maxScroll!: number;

  private scrollInterval!: number;
  private isScrolling = false;
  private readonly LEFT_CORRECTION = 100;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.scrollToElement();
  }

  ngOnChanges() {
    this.scrollToElement();
    this.updateMaxScroll();
  }

  ngAfterViewInit() {
    this.updateMaxScroll();
  }

  get currentScroll(): number {
    return this.container ? Math.ceil(this.container.nativeElement.scrollLeft) : 0;
  }

  scrollTo(direction: string) {
    if (this.isScrolling) {
      return;
    }

    this.arrowClick.emit();
    this.isScrolling = true;
    const containerElem = this.container.nativeElement;
    const scrollAmount = containerElem.offsetWidth / 2;
    let newPosition = direction === 'right' ? this.currentScroll + scrollAmount : this.currentScroll - scrollAmount;
    if (newPosition < 0) {
      newPosition = 0;
    }
    if (newPosition > this.maxScroll) {
      newPosition = this.maxScroll;
    }

    let scrollLeft = containerElem.scrollLeft;
    this.scrollInterval = setInterval(() => {
      if (direction === 'right') {
        scrollLeft += 20;
        containerElem.scrollLeft = scrollLeft;
        if (Math.ceil(containerElem.scrollLeft) >= newPosition) {
          this.stopScroll();
        }
      } else {
        scrollLeft -= 20;
        containerElem.scrollLeft = scrollLeft;
        if (containerElem.scrollLeft <= newPosition) {
          this.stopScroll();
        }
      }
      this.cdr.detectChanges();
    }, 5) as MSafeAny;
  }

  updateScrolFromTabSelected() {
    setTimeout(() => {
      this.scrollToElement();
    }, 5);
  }

  ngOnDestroy() {
    clearInterval(this.scrollInterval);
  }

  private stopScroll() {
    clearInterval(this.scrollInterval);
    this.isScrolling = false;
  }

  private async scrollToElement() {
    await domChanges();
    const containerElem = this.container.nativeElement;
    const element: HTMLElement = containerElem.querySelector(`#${this.scrollToElementID}`) as HTMLElement;
    const horizontalPosition = element && element.offsetLeft - this.LEFT_CORRECTION;

    containerElem.scrollTo({
      left: horizontalPosition,
      behavior: 'smooth'
    } as ScrollToOptions);
  }

  private async updateMaxScroll() {
    if (this.container) {
      await domChanges();
      this.maxScroll = this.container.nativeElement.scrollWidth - this.container.nativeElement.offsetWidth;
      this.cdr.detectChanges();
    }
  }
}
