import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-ptrab-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.shared.scss']
})
export class SuccessModalComponent implements OnInit {
  titleKey!: string;
  infoMsgKey!: string;
  requestPhone!: boolean;
  descriptionKey!: string;
  emailQuestion!: string;
  personalDataLink!: string;
  imageUrl!: string;
  ipadImageUrl!: string;
  hasAction!: boolean;

  imageSuccess!: string;
  readonly buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissMatModal()
    }
  ];

  private platform: Platform;
  modalManager: ModalManager;
  private navigationService: NavigationService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, injector: Injector) {
    this.platform = injector.get(Platform);
    this.modalManager = injector.get(ModalManager);
    this.navigationService = injector.get(NavigationService);
  }

  ngOnInit() {
    if (this.data) {
      this.titleKey = this.data.titleKey;
      this.infoMsgKey = this.data.infoMsgKey;
      this.requestPhone = this.data.requestPhone;
      this.descriptionKey = this.data.descriptionKey;
      this.emailQuestion = this.data.emailQuestion;
      this.personalDataLink = this.data.personalDataLink;
      this.imageUrl = this.data.imageUrl;
      this.ipadImageUrl = this.data.ipadImageUrl;
      this.hasAction = this.data.hasAction;
    } else {
      this.imageUrl = 'assets/imgs/employee-portal/ok-paysheet.svg';
      this.ipadImageUrl = 'assets/imgs/employee-portal/ok-paysheet-reduced.svg';
      this.hasAction = true;
    }

    if (this.requestPhone) {
      this.imageUrl = 'assets/imgs/employee-portal/group.svg';
      this.ipadImageUrl = 'assets/imgs/employee-portal/group.svg';
    }
    this.imageSuccess = this.platform.is('ipad') && this.ipadImageUrl ? this.ipadImageUrl : this.imageUrl;
  }

  openPage() {
    this.modalManager.dismissMatModal(true);

    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: PAGES.PERSONAL_DATA
    });

    this.navigationService.navigate(navEvent);
  }
}
