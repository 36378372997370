import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SuccessModalComponent } from '@app/ptrab/components/modals/success-modal/success-modal.component';
import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-direct-debit-success',
  templateUrl: './direct-debit-success.component.html',
  styleUrls: ['../success-modal.shared.scss']
})
export class DirectDebitSuccessComponent extends SuccessModalComponent implements OnInit {
  // eslint-disable-next-line
  readonly buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.goBack()
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    injector: Injector,
    private ptrabSessionManager: PtrabSessionManager
  ) {
    super(data, injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async goBack() {
    await this.ptrabSessionManager.checkSession();
    this.modalManager.dismissMatModal();
  }
}
